import { DialogController } from "@/controller/dialogController";

class Scene {

	constructor(id) {
    	this.id = id;
    }

	getId()
	{
		return this.id;

	}

	dialog(delay, message, isOwner, avatar, name, callback, isQuiz, callbackQuiz, scene)
	{
		DialogController.add(delay, message, isOwner, avatar, name, callback, isQuiz, callbackQuiz, scene);
	}


	quizUniqueChoice(delay, message, choices, callbackQuiz, callback, scene)
	{
		DialogController.quizUniqueChoice(delay, message, choices, true, 'theo', '', callbackQuiz, callback, scene);
	}


	quizFillIn(delay, message, datas, callbackQuiz, callback, scene)
	{
		DialogController.quizFillIn(delay, message, datas, true, 'theo', '', callbackQuiz, callback, scene);
	}	

	startDialog()
	{
		DialogController.next();
	}


}
export {Scene}