import { MainController } from "./mainController";
import $ from "jquery";
import { SoundController } from "./soundController";
import jsTypeText from 'js-type-text';

class DialogController {
	

	constructor() {
        if (!DialogController.instance) {
            return DialogController.instance;
        }

        DialogController.instance = this;
        DialogController.offsetFix = true;
        DialogController.forceDisplayNext = false;


        return this;
    }

	static init()
	{

		this.messagesStack = [];

		MainController.stopPropagation();

		
	}

	static isStackEmpty()
	{
		if(this.messagesStack.length > 0)
		{
			return false;
		}

		return true;
	}




	static add(delay, message, isOwner, avatar, name, callback, isQuiz, callbackQuiz, scene)
	{

		var classQuiz = '';
		if(isQuiz)
		{
			classQuiz = 'quiz';
		}



		var htmlMessage = "<div class='message left'><div class='title'><a data-href='/user/"+avatar+"' class='user'>"+name+"</a></div><div class='bubble'><div class='picture'><a data-href='/user/"+avatar+"' class='user'><img src='/assets/images/"+avatar+".jpg'></a></div><div class='text'>"+message+"</div></div></div>";

		if(isOwner)
		{
			htmlMessage = "<div class='message right'><div class='text'>"+message+"</div></div>";
		}

		var msgDisplayedCount = $("#chat .message").length;
		
		var msg = {
			delay: delay,
			htmlMessage: htmlMessage,
			callback: callback,
			callbackQuiz: callbackQuiz,
			isQuiz: isQuiz,
			isOwner: isOwner,
			scene: scene
		}
		
		this.messagesStack.push(msg);

		
		
		
		
	}


/*

<div class="message response right">
                    <div class="question">Que souhaitez-vous répondre ?</div>
                    <div class="choice active">
                        Oui je suis au taquet meuf !       
                    </div>
                    <div class="choice">
                        Je vais bien et toi, comment vas-tu ?      
                    </div>
                    <div class="choice">
                        Pas top, en fait, je viens de tomber … de tomber amoureux…      
                    </div>
                </div>

*/
	
	static quizUniqueChoice(delay, message, choices, isOwner, avatar, name, callbackQuiz, callback, scene)
	{
		var htmlChoices = '';
		var group = "g"+Math.floor(Date.now() / 1000);
		for (var i=0; i < choices.length; i++)
		{
			htmlChoices += "<input type='radio' id='"+choices[i].id+"'' name='"+group+"' value='"+choices[i].value+"'><label for='"+choices[i].id+"''>"+choices[i].label+"</label>";
		}
		var messageForm = "<div class='question'>"+message+"</div><form data-type='uniqueChoice'>"+htmlChoices+"<button type='submit'>Envoyer</button></form>";
		

		this.add(delay, messageForm, isOwner, avatar, name, callback, true, callbackQuiz, scene);
	}



	static quizFillIn(delay, message, datas, isOwner, avatar, name, callbackQuiz, callback, scene)
	{
		var htmlDatas = "<div class='fillIn'>" + datas + "</div>";
		
		var messageForm = "<div class='question'>"+message+"</div><form data-type='fillIn'>"+htmlDatas+"<br><button type='submit'>Envoyer</button></form>";
		
		this.add(delay, messageForm, isOwner, avatar, name, callback, true, callbackQuiz, scene);
	}	


	static next()
	{


		
		var lastMessage = this.messagesStack.shift(); // pop extrait la premiere valeur et la retire du tableau
		
		if(lastMessage != undefined)
		{
			setTimeout(DialogController.nextDelay, lastMessage.delay, lastMessage);
		}

		

	}

	static nextDelay(lastMessage)
	{

		$(lastMessage.htmlMessage).hide().appendTo("#chat").fadeIn(500);

		
		$("a.user").unbind("click");
		$("a.user").click(function(e){
			MainController.openProfile();
		});
		
		
		if( (lastMessage.isQuiz != true) )
		{
			



			var t = $("#chat .message:last-child .text").html()
				var speed = 10;

				jsTypeText.start({
					text: t,
					speed: speed,
					cursor: false,
					cursorSpeed: 350,
					cursorStyle: "vertical"
				  }, (result)=>{
					$("#chat .message:last-child .text").html(result)
				  });

				  setTimeout( (lastMessage)=> {SoundController.play('/assets/audios/chat.mp3'); DialogController.prepareNext(lastMessage); }, t.length*speed, lastMessage);


		}else
		{
			DialogController.prepareQuizz($("#chat .message form").last(), lastMessage);
			

			setTimeout(DialogController.prepareNext, 0, lastMessage);
		}


		

		var msgs = $(".chat .contener");

		msgs.stop().animate({scrollTop:$('.chat .contener')[0].scrollHeight}, 500, 'swing', function() { 
		   //console.log("Finished animating");
		});




		
		
	}

	static prepareNext(msg)
	{


		if(msg.callback != null)
		{
			msg.callback(msg.scene);
		}

		DialogController.next();
						
	}


	static prepareQuizz(form, msg)
	{
		/*
		$(form).find(".sortable").sortable(
			{
				
             	cursor: 'move',
             	helper: 'clone',
             	appendTo: 'body',
             	start: function(e, ui)
             	{
					var t = parseFloat($('#chat .message form ul.sortable li').css('padding-top'));
					var b = parseFloat($('#chat .message form ul.sortable li').css('padding-bottom'));
					var l = parseFloat($('#chat .message form ul.sortable li').css('padding-left'));
					var r = parseFloat($('#chat .message form ul.sortable li').css('padding-right'));
             		ui.helper.height( ui.helper.height() + t + b );
             		ui.helper.width( ui.helper.width() + l + r );
             	},
             	change: function(e, ui)
             	{
             		//console.log(e);
             		//console.log(ui);

             	}
			}
		);
*/
		$(form).submit(function( event ) {


			

			//var sortableList = $(this).find('.sortable');
			var results = $( this ).serializeArray();
			
/*
			if(sortableList.length > 0)
			{
				results = $(this).find('.sortable').sortable( "toArray" );
				
			}
*/
			if(results && results.length > 0)
			{
				SoundController.play('/assets/audios/chat.mp3');

				msg.callbackQuiz( results );

				//$(this).find('.sortable').sortable( "disable" );
				$(this).find('input, button').prop('disabled', true);
				$(this).find('label').addClass('disabled');

			}else
			{
				// erreur
			}
			
  			event.preventDefault();
		});
	}




	static clean()
	{
		$("#chat").empty();

	}

	static clear()
	{
		this.messagesStack = [];

		$("#chat .message").removeClass("active").fadeOut(500, function(e){
				$(this).remove();
				
		});
		
	}



}
export {DialogController}