import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import eventDispatcher from './eventDispatcher'
import VueGtag from "vue-gtag";


import './scss/app.scss'


const app = createApp(App);
/*
app.use(VueGtag, {
    config: { id: "UA-179026712-1" }
  }
);
*/
app.use(router);
app.config.globalProperties.eventDispatcher = eventDispatcher;
app.mount('#app');