<template>
  <div class="chat">
    <div class="wrapper">
        <div class="contener">
            <header>
                <img src="@/assets/images/flirter-logo.svg">
            </header>
            <div id="chat">
            </div>
        </div>
    </div>

    <div class="profile" style="display: none;">
        <div class="wrapper">
            <div class="contener">
                <header>
                    <img src="@/assets/images/flirter-logo.svg">
                </header>
                <div class="panel">
                    <div class="picture">
                        <img src="/assets/images/justine.jpg">
                    </div>
                    <div class="infos">
                            <h1>Justine, 20 ans</h1>
                            <h2>étudiante à Paris</h2>
                            <p>"J'adore les matous !!!"</p>
                    </div>
                </div>
                <a @click="closeProfile" class="bt-love"></a>
                <footer>
                    <a @click="closeProfile" id="join-chat"></a>
                </footer>
            </div>
        </div>
    </div>

    <div class="call" style="display: none;">
        <div class="wrapper">
            <div class="contener">
                
                <footer>
                    <div class="call-name"><span class="call-label small"></span> <span class="call-surname"></span></div>
                    <a @click="declineCall" class="phone-decline"></a>
                    <a @click="acceptCall" class="phone-accept"></a>
                </footer>
            </div>
        </div>
    </div>   
  </div>
</template>

<script>
import { MainController } from '@/controller/mainController'
export default {
  name: 'SceneView',
  data: function () {
    return {
    }
  },
  mounted() {
    
    MainController.init(this.$route.params.token, this.$route.params.scene);
  },
  methods: {
    closeProfile() {
        MainController.closeProfile();
    },
    declineCall() {
        MainController.declineCall();
    },
    acceptCall() {
        MainController.acceptCall();
    }
  }
  
}
</script>
<style lang="scss" scoped>
</style>