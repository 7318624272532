import { I18NEvent } from "@/events/i18nEvent";
import { Text } from "@/display/text";
import $ from "jquery";

class I18NController {

	constructor() {
        if (!I18NController.instance) {
            return I18NController.instance;
        }

        I18NController.instance = this;

        return this;
    }

	static load(file)
	{
		$.getJSON( file, function( data ) {
			Text.setData(data);
			I18NController.prepareDocument();
			window.dispatchEvent(new CustomEvent(I18NEvent.TRANSLATION_COMPLETE));
		});
		
	}

	static prepareDocument()
	{
		$('body').find('[data-translate-content]').each(function(){
			$(this).html( Text.get($(this).attr('data-translate-content')) );
		});

		$('body').find('[data-translate-src]').each(function(){
			$(this).attr('src', Text.get($(this).attr('data-translate-src')) );
		});
	}
}
export {I18NController}