<template>

  <div class="chat">
      <div class="wrapper">
          <div class="contener">
              <header>
                  <img src="@/assets/images/flirter-logo.svg">
              </header>
              <div id="chat">
                  <div class="complete">
                      <div class="complete-msg">
                          Continuez sur norbertweston.com
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'CompleteView',
  data: function () {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
  
}
</script>
<style lang="scss" scoped>
</style>