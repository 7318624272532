import { MainController } from "@/controller/mainController";
import { Text } from "@/display/text";
import { Scene } from "./scene";

class C6s1 extends Scene {

	constructor() {
       super("c6s1");
    }

	start()
	{
		this.firstDialog();
        
	}

	firstDialog()
	{

        this.dialog(0, Text.get("c6s1-dialog1"), false, "justine", "Justine");
        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c6s1-dialog2"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c6s1-dialog3"),
                    "value": 0
                }
            ],
            function(result){
                
	        	this.scene.secondDialog();
            },
            null,
            this
        );


		this.startDialog();

	}

	secondDialog()
	{
		this.dialog(3000, Text.get("c6s1-dialog4"), false, "justine", "Justine");
	    this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c6s1-dialog5"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c6s1-dialog6"),
                    "value": 1
                }
            ],
            function(result){
                
                if(result[0].value == 0)
                {
                    this.scene.dialog(3000, Text.get("c6s1-dialog7"), false, "justine", "Justine");
                    
                }else
                {
                    this.scene.dialog(3000, Text.get("c6s1-dialog8"), false, "justine", "Justine");
                }
            
                this.scene.thirdDialog();
            },
            null,
            this
        );
        this.startDialog();
	}

	thirdDialog()
	{
		this.dialog(3000, Text.get("c6s1-dialog9"), true);
        this.dialog(3000, Text.get("c6s1-dialog10"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c6s1-dialog11"), true);
        this.dialog(3000, Text.get("c6s1-dialog12"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c6s1-dialog13"), true);
        this.dialog(3000, Text.get("c6s1-dialog14"), false, "justine", "Justine");
        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c6s1-dialog15"),
                    "value": 0
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c6s1-dialog16"),
                    "value": 1
                }
            ],
            function(result){
                
                if(result[0].value == 0)
                {
                    this.scene.dialog(3000, Text.get("c6s1-dialog17"), false, "justine", "Justine");
                    this.scene.quizUniqueChoice(3000, Text.get("label-select-response"),
                        [
                            {
                                "id": "q4-choice1",
                                "label": Text.get("c6s1-dialog18"),
                                "value": 0
                            },
                            {
                                "id": "q4-choice2",
                                "label": Text.get("c6s1-dialog19"),
                                "value": 1
                            }
                        ],
                        function(result){
                            
                            this.scene.fourthDialog();
                        },
                        null,
                        this.scene
                    );
                    this.scene.startDialog();
                }else
                {
                    this.scene.fourthDialog();
                }
            
                
            },
            null,
            this
        );
        this.startDialog();
	}

    fourthDialog()
    {
        this.dialog(3000, Text.get("c6s1-dialog20"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c6s1-dialog21"), true);
        this.dialog(3000, Text.get("c6s1-dialog22"), false, "justine", "Justine");
        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q5-choice1",
                    "label": Text.get("c6s1-dialog23"),
                    "value": 0
                },
                {
                    "id": "q5-choice2",
                    "label": Text.get("c6s1-dialog24"),
                    "value": 1
                }
            ],
            function(result){
                
                
            
                this.scene.fifthDialog();
            },
            null,
            this
        );
        
        
        this.startDialog();
    }

    fifthDialog()
    {
        this.dialog(3000, Text.get("c6s1-dialog25"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c6s1-dialog26"), true);
        this.dialog(3000, Text.get("c6s1-dialog27"), false, "justine", "Justine");
        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q6-choice1",
                    "label": Text.get("c6s1-dialog28"),
                    "value": 0
                },
                {
                    "id": "q6-choice2",
                    "label": Text.get("c6s1-dialog29"),
                    "value": 1
                }
            ],
            function(result){
                
                
            
                this.scene.sixthDialog();
            },
            null,
            this
        );
        
            
        this.startDialog();
    }

    sixthDialog()
    {
        this.dialog(3000, Text.get("c6s1-dialog30"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c6s1-dialog31"), true);
        this.dialog(3000, Text.get("c6s1-dialog32"), false, "justine", "Justine", function(scene){
            MainController.call(Text.get("label-call"), Text.get("label-michou"), scene.endCall, scene.endCall);
        }, false, null, this);
        

        this.dialog(3000, Text.get("c6s1-dialog33"), true);
        this.startDialog();   
    }

    endCall()
    {
        
        var completePromise = MainController.completeChapter("chapter6");

        Promise.resolve(completePromise).then((result) => {

            var bookmarkPromise = MainController.setBookMark("chapter7Intro");
            Promise.resolve(bookmarkPromise).then((result) => {
                MainController.completeScene();
            }).catch((error) => {
                console.log(error);
            }); 
            
        }).catch((error) => {
            console.log(error);
        });
        
        
    }


}
export {C6s1}