
import { createRouter, createWebHistory } from 'vue-router'
import ErrorView from '@/views/ErrorView.vue';
import SceneView from '@/views/SceneView.vue';
import CompleteView from '@/views/CompleteView.vue';

import authService from '../services/authService';
import redirectService from '../services/redirectService';
import eventDispatcher from '../eventDispatcher';
import eventConfig from '@/configs/eventConfig';

const defaultMeta = {
};

const requireAuthMeta = {
  requiresAuth: true
};

const routes = [
  
  {
    path: '/:scene/:token',
    name: 'Scene',
    component: SceneView,
    meta: requireAuthMeta
  },
  {
    path: '/complete',
    name: 'Complete',
    component: CompleteView,
    meta: defaultMeta
  },
  {
    path: '/error/:error?',
    name: 'Error',
    component: ErrorView,
    meta: defaultMeta
  }
];

var router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    authService.isUserAuth(to.params.token).then(
      () => {
        
        next();
      },
      error => {
        
        next({ name: 'Error', params: {error: "Vous n'êtes pas autorisé à afficher la page."} });
      }
    );

  }else{
    
        next();
    
  }


});

eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
  router.push({ name: 'Error', params: {error: "Vous n'êtes pas autorisé à afficher la page."} });
})

export default router
