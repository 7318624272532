<template>

  <div class="chat">
    <div class="wrapper">
        <div class="contener">
            <header>
                <img src="@/assets/images/flirter-logo.svg">
            </header>
            <div id="chat">
                <div class="error">
                    <div class="error-msg">
                        {{ $route.params.error }}
                    </div>
                </div>

            </div>
        </div>
    </div>


  </div>
</template>

<script>

export default {
  name: 'ErrorView',
  data: function () {
    return {
    }
  },
  mounted() {
    console.log(this.$route.params)
  },
  methods: {
  }
  
}
</script>
<style lang="scss" scoped>
</style>