import { MainController } from "@/controller/mainController";
import { Text } from "@/display/text";
import { Scene } from "./scene";

class C9s1 extends Scene {

	constructor() {
       super("c9s1");
    }

	start()
	{
        
        this.firstDialog();
	}

	firstDialog()
	{
    
        this.dialog(1000, Text.get("c9s1-dialog1"), true);
        this.dialog(3000, Text.get("c9s1-dialog2"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog3"), true);
        this.dialog(6000, Text.get("c9s1-dialog4"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog5"), true);
        this.dialog(6000, Text.get("c9s1-dialog6"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog7"), true);
        this.dialog(6000, Text.get("c9s1-dialog8"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog9"), true);
        this.dialog(6000, Text.get("c9s1-dialog10"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog11"), true);
        this.dialog(3000, Text.get("c9s1-dialog12"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog13"), true);
        this.dialog(6000, Text.get("c9s1-dialog14"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog15"), true);
        this.dialog(12000, Text.get("c9s1-dialog16"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c9s1-dialog17"), true);
        this.dialog(3000, Text.get("c9s1-dialog18"), true, null, null, function(scene){
            MainController.call(Text.get("label-call"), Text.get("label-norbert-weston"), scene.endCall, scene.endCall);
        }, false, null, this);
        this.dialog(6000, Text.get("c9s1-dialog19"), false, "justine", "Justine");
        this.startDialog();

    }
    

    endCall()
    {
        
        var completePromise = MainController.completeChapter("chapter9");

        Promise.resolve(completePromise).then((result) => {

            var bookmarkPromise = MainController.setBookMark("chapter10Intro");
            Promise.resolve(bookmarkPromise).then((result) => {
                MainController.completeScene();
            }).catch((error) => {
                console.log(error);
            }); 
            
        }).catch((error) => {
            console.log(error);
        });
        
        
    }


}
export {C9s1}