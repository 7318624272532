import { MainController } from "@/controller/mainController";
import { Text } from "@/display/text";
import { Scene } from "./scene";

class C5s1 extends Scene {

	constructor() {
       super("c5s1");
    }

	start()
	{
        this.firstDialog();
        
	}

	firstDialog()
	{
        
		this.dialog(0, Text.get("c5s1-dialog1"), false, "justine", "Justine");
		this.dialog(3000, Text.get("c5s1-dialog2"), true);
		this.dialog(3000, Text.get("c5s1-dialog3"), false, "justine", "Justine");

        this.quizUniqueChoice(5000, Text.get("label-select-response"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c5s1-dialog4"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c5s1-dialog5"),
                    "value": 1
                }
            ],
            function(result){
                
                this.scene.secondDialog();
                
            },
            null,
            this
        );



		this.startDialog();

	}

	secondDialog()
	{
		this.dialog(3000, Text.get("c5s1-dialog6"), false, "justine", "Justine");
	    this.dialog(3000, Text.get("c5s1-dialog7"), true);

	    this.dialog(3000, Text.get("c5s1-dialog8"), false, "justine", "Justine");
	    this.quizUniqueChoice(2000, Text.get("label-select-response"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c5s1-dialog9"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c5s1-dialog10"),
                    "value": 1
                }
            ],
            function(result){
                
	        	this.scene.thirdDialog();
            },
            null,
            this
        );

        this.startDialog();
	}

	thirdDialog()
	{
		this.dialog(3000, Text.get("c5s1-dialog11"), false, "justine", "Justine");
	    this.dialog(3000, Text.get("c5s1-dialog12"), true);
	    this.dialog(3000, Text.get("c5s1-dialog13"), false, "justine", "Justine");
	    this.quizUniqueChoice(2000, Text.get("label-select-response"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c5s1-dialog14"),
                    "value": 0
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c5s1-dialog15"),
                    "value": 1
                }
            ],
            function(result){
                
	        	this.scene.fourthDialog()
            },
            null,
            this
        );

        this.startDialog();
	}

    fourthDialog()
    {
        this.dialog(3000, Text.get("c5s1-dialog16"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c5s1-dialog17"), true);
        this.dialog(3000, Text.get("c5s1-dialog18"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c5s1-dialog19"), true);
        this.dialog(3000, Text.get("c5s1-dialog20"), false, "justine", "Justine");
        this.quizUniqueChoice(2000, Text.get("label-select-response"),
            [
                {
                    "id": "q4-choice1",
                    "label": Text.get("c5s1-dialog21"),
                    "value": 0
                },
                {
                    "id": "q4-choice2",
                    "label": Text.get("c5s1-dialog22"),
                    "value": 1
                }
            ],
            function(result){
                
                this.scene.fifthDialog();
            },
            null,
            this
        );

        this.startDialog();
    }

    fifthDialog()
    {
        this.dialog(3000, Text.get("c5s1-dialog23"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c5s1-dialog24"), true);
        this.dialog(6000, Text.get("c5s1-dialog25"), false, "justine", "Justine");

        this.quizUniqueChoice(2000, Text.get("label-select-response"),
            [
                {
                    "id": "q5-choice1",
                    "label": Text.get("c5s1-dialog26"),
                    "value": 0
                },
                {
                    "id": "q5-choice2",
                    "label": Text.get("c5s1-dialog27"),
                    "value": 1
                }
            ],
            function(result){
                
                this.scene.sixthDialog();

            },
            null,
            this
        );

        this.startDialog();
    }

    sixthDialog()
    {
        // sonnerie tel + apparition appel
        MainController.call(Text.get("label-call"), Text.get("label-mom"), this.endCall, this.endCall);

        this.dialog(0, Text.get("c5s1-dialog28"), true);
        this.dialog(2000, Text.get("c5s1-dialog29"), false, "justine", "Justine")

        this.startDialog();   
    }

    endCall()
    {
        
        var completePromise = MainController.completeChapter("chapter5");

        Promise.resolve(completePromise).then((result) => {

            var bookmarkPromise = MainController.setBookMark("chapter6Intro");
            Promise.resolve(bookmarkPromise).then((result) => {
                MainController.completeScene();
            }).catch((error) => {
                console.log(error);
            }); 
            
        }).catch((error) => {

            console.log(error);
        });

        
    }


}
export {C5s1}