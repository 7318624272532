import { I18NEvent } from "@/events/i18nEvent";
import { C5s1 } from "@/scenes/c5s1";
import { C6s1 } from "@/scenes/c6s1";
import { C7s2 } from "@/scenes/c7s2";
import { C8s2 } from "@/scenes/c8s2";
import { C9s1 } from "@/scenes/c9s1";
import { DialogController } from "./dialogController";
import { I18NController } from "./i18Controller";
import { SoundController } from "./soundController";
import $ from "jquery";
import apiService from "@/services/apiService";

class MainController {

	constructor() {
        if (!MainController.instance) {
            return MainController.instance;
        }

        MainController.instance = this;

        return this;
    }

	static init(token, scene, gameUrl)
	{

        this.token = token;
		this.scene = scene;
		this.gameUrl = gameUrl;

		window.addEventListener( I18NEvent.TRANSLATION_COMPLETE, function(e){
			MainController.start();
		}, this);

		I18NController.load('/datas/fr.json');

		var c5s1 = new C5s1();
		var c6s1 = new C6s1();
		var c7s2 = new C7s2();
		var c8s2 = new C8s2();
		var c9s1 = new C9s1();

		this.scenes = [ c5s1, c6s1, c7s2, c8s2, c9s1 ];

		

	}

	static start()
	{
		
    	DialogController.init();
    	
		SoundController.init();

    	$(".messages").on("mousedown mouseup", function(e){
    		e.stopPropagation();
    	});


		for (var i = 0; i < this.scenes.length; i++)
		{

			if(this.scenes[i].getId() == this.scene)
			{
				this.scenes[i].start();
				break;
			}
		}

	}

	static stopPropagation(){
		$('*').on('mousedown mouseup touchstart touchend', function(event){
		    event.stopPropagation();
		});
	}

	static openProfile()
	{
		$(".profile").show("slow");
	}

	static closeProfile()
	{
		$(".profile").hide("slow");
	}

	static call(label, surname, callBackAccept, callBackDecline)
	{
		this.callCallBackAccept = callBackAccept;
		this.callCallBackDecline = callBackDecline;

		$(".call .call-label").html(label);
		$(".call .call-surname").html(surname);
		
		$(".call").show("slow");
		//$(".call .call-name").effect( "shake", { direction: "up", times: 10, distance: 5}, 3000 );
		SoundController.play('/assets/audios/ringtone.mp3', 'ringtone', true);
	}

	static acceptCall()
	{
		SoundController.stop('ringtone');
		SoundController.play('/assets/audios/call-accept.mp3');

		$(".call").hide("slow");
		if(this.callCallBackAccept)
		{
			this.callCallBackAccept();
		}
	}

	static declineCall()
	{
		SoundController.stop('ringtone');
		SoundController.play('/assets/audios/call-decline.mp3');

		$(".call").hide("slow");
		if(this.callCallBackDecline)
		{
			this.callCallBackDecline();
		}
	}

	static completeChapter(name)
	{

		return apiService
		      .post('/user/completechapter',
		      	{
		            chapter: name
		        }, this.token);
	}

	static setBookMark(name)
	{
		return apiService
		      .post('/user/setbookmark',
		      	{
		            bookmark: name
		        }, this.token);
	}

	static completeScene()
	{
		location.href = "/complete";
	}



}
export {MainController}