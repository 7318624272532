import { MainController } from "@/controller/mainController";
import { Text } from "@/display/text";
import { Scene } from "./scene";

class C8s2 extends Scene {

	constructor() {
       super("c8s2");
       
    }

	start()
	{
        this.firstDialog();
	}

	firstDialog()
	{
        
        this.dialog(0, Text.get("c8s2-dialog1"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog2"), true);
        this.dialog(3000, Text.get("c8s2-dialog3"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog4"), true);
        this.dialog(15000, Text.get("c8s2-dialog5"), true);
        this.dialog(6000, Text.get("c8s2-dialog6"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog7"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog8"), true);
        this.dialog(15000, Text.get("c8s2-dialog9"), true);
        this.dialog(3000, Text.get("c8s2-dialog10"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog11"), true);
        this.dialog(6000, Text.get("c8s2-dialog12"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c8s2-dialog13"), true);
        this.dialog(8000, Text.get("c8s2-dialog14"), false, "justine", "Justine");
        this.dialog(8000, Text.get("c8s2-dialog15"), true);
        this.dialog(8000, Text.get("c8s2-dialog16"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c8s2-dialog17"), true);
        this.dialog(6000, Text.get("c8s2-dialog18"), false, "justine", "Justine");
        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c8s2-dialog19"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c8s2-dialog20"),
                    "value": 1
                }
            ],
            function(result){
                
                if(result[0].value == 0)
                {
                    this.scene.dialog(6000, Text.get("c8s2-dialog21"), false, "justine", "Justine");
                    
                }else
                {
                    this.scene.dialog(3000, Text.get("c8s2-dialog22"), false, "justine", "Justine");
                }
            
                this.scene.secondDialog();
            },
            null,
            this
        );
        this.startDialog();

	}

    secondDialog()
	{
        this.dialog(6000, Text.get("c8s2-dialog23"), true, null, null, function(scene){
            MainController.call(Text.get("label-call"), Text.get("label-unknown-number"), scene.endCall, scene.endCall);
        }, false, null, this);
        this.dialog(3000, Text.get("c8s2-dialog24"), true);
        this.dialog(3000, Text.get("c8s2-dialog25"), false, "justine", "Justine");
        this.startDialog();
    }


    endCall()
    {
        
        var completePromise = MainController.completeChapter("chapter8");

        Promise.resolve(completePromise).then((result) => {

            var bookmarkPromise = MainController.setBookMark("chapter9Intro");
            Promise.resolve(bookmarkPromise).then((result) => {
                MainController.completeScene();
            }).catch((error) => {
                console.log(error);
            }); 
            
        }).catch((error) => {
            console.log(error);
        });
        
        
    }


}
export {C8s2}