import { MainController } from "@/controller/mainController";
import { Text } from "@/display/text";
import { Scene } from "./scene";

class C7s2 extends Scene {

	constructor() {
       super("c7s2");
       
    }

	start()
	{
        this.firstDialog();
	}

	firstDialog()
	{
        
        this.dialog(0, Text.get("c7s2-dialog1"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c7s2-dialog2"), true);
        this.dialog(3000, Text.get("c7s2-dialog3"), false, "justine", "Justine");
        this.dialog(3000, Text.get("c7s2-dialog4"), true);
        this.dialog(3000, Text.get("c7s2-dialog5"), false, "justine", "Justine");

        this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c7s2-dialog6"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c7s2-dialog7"),
                    "value": 0
                }
            ],
            function(result){
                
	        	this.scene.secondDialog();
            },
            null,
            this
        );


		this.startDialog();

	}

	secondDialog()
	{
		this.dialog(3000, Text.get("c7s2-dialog8"), false, "justine", "Justine");
	    this.quizUniqueChoice(3000, Text.get("label-select-response"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c7s2-dialog9"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c7s2-dialog10"),
                    "value": 1
                }
            ],
            function(result){
                
                if(result[0].value == 0)
                {
                    this.scene.dialog(8000, Text.get("c7s2-dialog11"), false, "justine", "Justine");
                    
                }else
                {
                    this.scene.dialog(3000, Text.get("c7s2-dialog12"), false, "justine", "Justine");
                }
            
                this.scene.thirdDialog();
            },
            null,
            this
        );
        this.startDialog();
	}

	thirdDialog()
	{
        this.dialog(12000, Text.get("c7s2-dialog13"), true);
        this.dialog(6000, Text.get("c7s2-dialog14"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog15"), true);
        this.dialog(6000, Text.get("c7s2-dialog16"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog17"), true);
        this.dialog(6000, Text.get("c7s2-dialog18"), false, "justine", "Justine");
        this.quizUniqueChoice(200, Text.get("label-select-response"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c7s2-dialog19"),
                    "value": 0
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c7s2-dialog20"),
                    "value": 1
                }
            ],
            function(result){
                         
                this.scene.fourthDialog();
            },
            null,
            this
        );
        this.startDialog();
    }

    fourthDialog()
	{
        this.dialog(6000, Text.get("c7s2-dialog21"), true);
        this.dialog(6000, Text.get("c7s2-dialog22"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog23"), true);
        this.dialog(6000, Text.get("c7s2-dialog24"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog25"), true);
        this.dialog(6000, Text.get("c7s2-dialog26"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog27"), true);
        this.dialog(6000, Text.get("c7s2-dialog28"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog29"), true);
        this.dialog(6000, Text.get("c7s2-dialog30"), false, "justine", "Justine");
        this.dialog(6000, Text.get("c7s2-dialog31"), true);
        this.dialog(6000, Text.get("c7s2-dialog32"), false, "justine", "Justine", function(scene){
            MainController.call(Text.get("label-call"), Text.get("label-norbert-weston"), scene.endCall, scene.endCall);
        }, false, null, this);
        

        this.dialog(3000, Text.get("c7s2-dialog33"), true);
        this.startDialog();   
    }


    endCall()
    {
        
        var completePromise = MainController.completeChapter("chapter7");

        Promise.resolve(completePromise).then((result) => {

            var bookmarkPromise = MainController.setBookMark("chapter8Intro");
            Promise.resolve(bookmarkPromise).then((result) => {
                MainController.completeScene();
            }).catch((error) => {
                console.log(error);
            }); 
            
        }).catch((error) => {
            console.log(error);
        });
        
        
    }


}
export {C7s2}